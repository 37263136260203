import { Card } from '@jsluna/card'
import ComponentSwitch from '../..'
import { ISemanticBox } from '../../../../../types/ComponentTypes/Components'
import { useState } from 'react'
import { IconButton } from '@jsluna/button'
import { ArrowDown, ArrowUp } from '@jsluna/icons'

function SemanticBoxComponent({ components, key, style }: ISemanticBox) {

  const [accordianOpen, setAccordianOpen] = useState(false)

  const handleAccordianBox = () =>
    accordianOpen ? setAccordianOpen(false) : setAccordianOpen(true)

  return (
    <div>
      {
        {
          information: (
            <Card className="s-information-box s-semantic-box">
              {components.map((component: any ) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>
          ),
          warning: (
            <Card className="s-warning-box s-semantic-box">
              {components.map((component: any) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>
          ),
          error: (
            <Card className="s-error-box s-semantic-box">
              {components.map((component: any) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>
          ),
          success: (
            <Card className="s-success-box s-semantic-box">
              {components.map((component: any) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>
          ),
          summary: (
            <Card className="s-summary-box s-semantic-box">
              {components.map((component: any) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>    
          ), 
          summary_investigation: (
            <Card className={
              !accordianOpen ? 's-summary-box s-overflow' : 's-summary-box s-semantic-box'
            }>
              {components.map((component: any) => (
                <ComponentSwitch
                  {...component}
                  key={`${key}-${component.id}`}
                />
              ))}
            </Card>    
          ), 
        }[style]
      }
      {style.includes('investigation') ? !accordianOpen ? (
            <IconButton
              variant="text"
              label="Read more"
              onClick={handleAccordianBox}
              className={'s-accordian'}
              id="a-back-button"
            >
              <ArrowDown size="small" />
            </IconButton>
          ) : (
            <IconButton
              variant="text"
              label="Read less"
              onClick={handleAccordianBox}
              className={'s-accordian'}
              id="a-back-button"
            >
              <ArrowUp size="small" />
            </IconButton>
          ): null}
    </div>
  )
}

export default SemanticBoxComponent