import reportingApi from '../api/reportingApi'
import { useReducer } from 'react'

const INIT = 'init'
const UPDATE_STORE_CODE = 'update_store_code'
const UPDATE_ANSWER = 'update_answer'
const UPDATE_METADATA = 'update_metadata'
const RESET_METADATA = 'reset metadata'
const SET_LOADING = 'set_loading'
const UPDATE_PAGE = 'update_page'
const NEXT = 'next'
const BACK = 'back'
const CANCEL = 'cancel'
const RESET = 'reset'
const ERROR = 'error'
const SAVE = 'save'

export const initialState = {
  loading: true,
  answers: {},
  submitted: false,
  error: false,
  metadata: {},
  initialized: false,
  sessionId: null,
}

export const init = (sessionId: any, investigationId: any) => ({
  type: INIT,
  sessionId,
  investigationId
})

export const updateStoreCode = (storeCode: any) => ({
  type: UPDATE_STORE_CODE,
  storeCode
})

export const updateAnswer = (componentId: any, answer: any) => ({
  type: UPDATE_ANSWER,
  componentId,
  answer
})

export const updateMetadata = (componentId: any, value: any) => ({
  type: UPDATE_METADATA,
  componentId,
  value
})

export const resetMetadata = () => ({
  type: RESET_METADATA
})

export const setLoading = () => ({
  type: SET_LOADING
})

export const updatePage = (payload: any) => ({
  type: UPDATE_PAGE,
  payload
})

export const error = (message: string, exception: unknown) => ({
  type: ERROR,
  message,
  exception
})

export const next = ({ id, value }: any) => ({
  type: NEXT,
  overrides: (id && { id, value }) || null
})

export const save = () => ({ type: SAVE })

export const back = () => ({ type: BACK })
export const cancel = () => ({ type: CANCEL })
export const reset = () => ({ type: RESET })

export function reducer(state: any, action: any) {
  switch (action.type) {
    case INIT:
      return { ...state, sessionId: action.sessionId, investigationId: action.investigationId, initialized: true}
    case UPDATE_STORE_CODE:
      return { ...state, storeCode: action.storeCode }
    case SET_LOADING:
      return { ...state, loading: true }
    case UPDATE_PAGE: {
      const newState = {
        ...state,
        serviceType: action.payload?.serviceType,
        id: action.payload?.id,
        sessionId: action.payload?.sessionId,
        components: action.payload?.components,
        loading: false,
        metadata: {},
        action: '',
        submitted: action.payload?.submitted === true,
        hasErrors: action.payload?.hasErrors === false
      }

      if (
        state.serviceType !== newState.serviceType ||
        state.id !== newState.id
      )
        newState.answers = {}

      newState.answers = {
        ...(newState?.answers || {}),
        ...(action.payload?.answerOverride || {})
      }

      return newState
    }
    case UPDATE_ANSWER: {
      return {
        ...state,
        answers: { ...state.answers, [action.componentId]: action.answer }
      }
    }
    case RESET_METADATA: {
      return {
        ...state,
        metadata: {}
      }
    }
    case UPDATE_METADATA: {
      return {
        ...state,
        metadata: { ...state.metadata, [action.componentId]: action.value }
      }
    }
    case ERROR: {
      return {
        ...state,
        error: { exception: action.exception, message: action.message },
        loading: false
      }
    }
  }
}

export const asyncDispatch =
  (dispatcher: any, state: any, apiClient: any) => async (action: any) => {
    switch (action.type) {
      case INIT: {
        dispatcher(action)
        try {
          const result = await reportingApi.getQuestions(apiClient, {
            sessionId: action.sessionId,
            investigationId: action.investigationId,
            metadata: {}
          })
          dispatcher(updatePage(result))
        } catch (e) {
          dispatcher(error('Failed to load questions', e))
        }
        break
      }
      case NEXT: {
        try {
          dispatcher(setLoading())
          const payload = {
            sessionId: state.sessionId,
            storeCode: state.storeCode,
            answers: state.answers,
            action: state.action,
            metadata: state.metadata
          }

          if (action.overrides) {
            payload.answers = {
              ...payload.answers,
              [action.overrides.id]: action.overrides.value
            }
          }

          const result = await reportingApi.getQuestions(apiClient, payload)

          dispatcher(updatePage(result))
          window.scrollTo(0, 0)
        } catch (e) {
          dispatcher(error('Failed to submit answers', e))
        }
        break
      }
      case BACK: {
        try {
          dispatcher(setLoading())
          const result = await reportingApi.getQuestions(apiClient, {
            sessionId: state.sessionId,
            storeCode: state.storeCode,
            answer: state.answers,
            action: 'back',
            metadata: state.metadata
          })

          dispatcher(updatePage(result))
        } catch (e) {
          dispatcher(error('Failed to go back', e))
        }
        break
      }
      case RESET: {
        try {
          const result = await reportingApi.getQuestions(apiClient, {
            storeCode: state.storeCode,
            action: 'reset',
            metadata: {}
          })
          dispatcher(updatePage(result))
        } catch (e) {
          dispatcher(error('Failed to reset', e))
        }
        break
      }
      case SAVE: {
        try {
          dispatcher(setLoading())
          const payload = {
            sessionId: state.sessionId,
            storeCode: state.storeCode,
            answers: state.answers,
            action: state.action,
            metadata: state.metadata
          }

          const result = await reportingApi.getQuestions(apiClient, payload)

          dispatcher(updatePage(result))
          window.scrollTo(0, 0)
        } catch (e) {
          dispatcher(error('Failed to save answers', e))
        }
        break
      }
      case CANCEL: {
        try {
          dispatcher(setLoading())
          const result = await reportingApi.getQuestions(apiClient, {
            storeCode: state.storeCode,
            metadata: {}
          })

          dispatcher(updatePage(result))
        } catch (e) {
          dispatcher(error('Failed to load questions', e))
        }
        break
      }
      default:
        dispatcher(action)
        break
    }
  }

const useReportingReducer = (apiClient: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return [state, asyncDispatch(dispatch, state, apiClient)]
}

export default useReportingReducer
